/* raleway-300 - latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('../fonts/raleway-v18-latin-ext-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/raleway-v18-latin-ext-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* raleway-regular - latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('../fonts/raleway-v18-latin-ext-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/raleway-v18-latin-ext-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* raleway-500 - latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('../fonts/raleway-v18-latin-ext-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/raleway-v18-latin-ext-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* raleway-600 - latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: local(''),
       url('../fonts/raleway-v18-latin-ext-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/raleway-v18-latin-ext-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* raleway-700 - latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('../fonts/raleway-v18-latin-ext-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/raleway-v18-latin-ext-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* raleway-300italic - latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 300;
  src: local(''),
       url('../fonts/raleway-v18-latin-ext-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/raleway-v18-latin-ext-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* raleway-italic - latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  src: local(''),
       url('../fonts/raleway-v18-latin-ext-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/raleway-v18-latin-ext-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* raleway-500italic - latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 500;
  src: local(''),
       url('../fonts/raleway-v18-latin-ext-500italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/raleway-v18-latin-ext-500italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* raleway-600italic - latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 600;
  src: local(''),
       url('../fonts/raleway-v18-latin-ext-600italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/raleway-v18-latin-ext-600italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* raleway-700italic - latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  src: local(''),
       url('../fonts/raleway-v18-latin-ext-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/raleway-v18-latin-ext-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

html {
    scroll-behavior: smooth;
}

* {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
}

*[id]::before {
    display: block;
    content: " ";
    visibility: hidden;
    margin-top: calc(-6rem - 50px);
    padding-top: calc(6rem + 50px);
    pointer-events: none;
}


.line-break {
    white-space: pre-line;
}

a[target="_blank"]::after {
    content: "";
    background-image: url("../images/open_in_new.svg");
    width: 17px;
    height: 17px;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-left: 3px;
    margin-top: 3px;
}
a[target="_blank"] {
    margin-right: 22px;
}

.custom-center {
    top: 50%;
    transform: translateY(-50%);
}

.icon {
    height: 20px;
    width: 20px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.icon.arrow-back {
    background-image: url(../images/arrow_back.svg);
}
.icon.arrow-down {
    background-image: url(../images/arrow_down.svg);
}
.icon.heart {
    background-image: url(../images/heart.svg);
}
.icon.menu {
    background-image: url(../images/menu.svg);
}
.icon.close {
    background-image: url(../images/close.svg);
}

.btn-primary {
  @apply font-medium bg-primary hover:bg-primary-dark border-2 rounded border-primary p-3 text-white transition-colors;
}
.btn-secondary {
  @apply font-medium hover:bg-secondary border-2 rounded border-primary p-3 text-primary hover:text-primary-dark transition-colors;
}
.link {
  @apply text-primary hover:text-primary-dark hover:underline transition-colors;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
